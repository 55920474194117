/**
 * Container
 */

import React, { MutableRefObject } from 'react';
import classnames from 'classnames';
import styles from './Container.module.css';


interface Props {
  className?: string;
  children: any;
  innerRef?: MutableRefObject<HTMLInputElement>
}
const Container = (
  ({ className, children, innerRef }: Props) => 
    <div className={classnames(styles.Root, className)} ref={innerRef}>
      <>{children}</>
    </div>
);

export default Container;
