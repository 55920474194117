import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { ServiceUnitType } from './types/common';

export enum PaymentMethodTypes {
  AMEX = 'amex',
  MAESTRO = 'maestro',
  MC = 'mc',
  VISA = 'visa',
  VISADANKORT = 'visadankort',
  DANKORT = 'dankort',
  MC_APPLEPAY = 'mc_applepay',
  VISA_APPLEPAY = 'visa_applepay',
  VISA_GOOGLEPAY = 'visa_googlepay',
  MC_GOOGLEPAY = 'mc_googlepay',
  GOOGLEPAY = 'googlepay',
  PAYPAL = 'paypal',
}

export interface AddLicensePlateRequest {
  lpn: string;
  primaryLocation: string | null;
  membershipType: string;
}

export enum OldResponseCodes {
  OK = 'ok',
  ERROR = 'error',
}

export interface AddNewLicensePlateResponse {
  status: OldResponseCodes;
  result: {
    membership: string;
  };
}

export enum MembershipType {
  SINGLE = 'single',
  NORMAL = '',
}

export interface ValidateLicensePlateRequest {
  license_plate: string;
  membershipType: MembershipType;
}

export interface ValidateLicensePlateResponse {
  http_code: number;
  status: OldResponseCodes;
  response: {
    status: OldResponseCodes;
  };
}

interface Location {
  changeable_date: Date | null;
  last_change: Date | null;
  name: string | null;
  uid: string;
}

export interface LocationSection {
  streetAddress: string;
  country: null;
  latitude: number;
  longitude: number;
  zoom: string;
}

interface LicensePlate {
  changeAble: boolean;
  changeable_date: Date | null;
  last_change: Date | null;
  plate: string;
  uid: string;
}

interface NextAvailablePeriods {
  start: Date;
  end: Date;
}

interface Pause {
  nextAvailablePeriods: NextAvailablePeriods[];
  periodsPaused: any[];
  remainingPeriods: number;
}

export interface Memberships {
  has_atav: boolean;
  is_editable: boolean;
  is_expiring: boolean;
  is_pre_order: boolean;
  is_single_wash: boolean;
  is_terminated: boolean;
  start_date: Date;
  status: number;
  status_string: string;
  uid: string;
  location: Location;
  license_plate: LicensePlate;
  pause: Pause;
}

export interface CheckPipResponse {
  serviceUnit: string;
  status: string;
  price: number;
  mebership?: string;
  session?: string;
  startTime?: string;
}

export interface GetIosResponse {
  bus_address: number;
  color: string;
  created_at: Date;
  fc: number;
  name: string;
  uid: string;
  updated_at: Date;
}

export interface CardDetails {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  type: PaymentMethodTypes;
}

export interface Subscriptions {
  marketing_20211201: boolean;
  products: boolean;
  newsletter: boolean;
  monthly_invoice: boolean;
}

export interface UserMe {
  address: null | string;
  cardDetails: CardDetails;
  city: null | string;
  email: string;
  firstname: null | string;
  has_card: boolean;
  isBusiness: boolean;
  lastname: null | string;
  phone: string;
  phone_prefix: string;
  pin_code: string;
  subscriptions: Subscriptions;
  uid: string;
  wallet: string;
  zipcode: null | string;
  walletAgreements: string[];
}

export interface LoginRequest {
  email: string;
  password?: string;
}

export interface LoginResponse {
  http_code: number;
  response: {
    token: string;
    type: string;
  };
  status: OldResponseCodes;
}

export interface DeAuthResponse {
  http_code: number;
  response: {
    changes: boolean;
    token: string;
  };
  status: OldResponseCodes;
}

export enum Identifiers {
  de = 'de',
  dk = 'dk',
  se = 'se',
  no = 'no',
}

export interface Country {
  Name: string;
  Phone_prefix: string;
  Language: string;
  Identifiers: Identifiers;
}

export interface CountryResponse {
  http_code: number;
  response: Country[];
  status: OldResponseCodes;
}

export interface ValidateEmailRequest {
  email: string;
}

export interface ValidateEmailResponse {
  message: string;
}

export enum IdentityProvider {
  CHECKOUT = 'checkout',
  PIP = 'pip',
  LOGIN = 'login',
}

export interface CreateUserRequest {
  consent: boolean;
  email: string;
  password: string;
  phone: string;
  phone_prefix: string;
  serviceId?: string;
  identityProvider?: IdentityProvider;
  businessName?: string;
  businessVatNumber?: string;
  marketingConsent?: boolean;
}

export interface CreateUserResponse {
  token: string;
  uid: string;
}

export interface PaymentRequest {
  membershipsid: string[] | number[];
  serviceUnitUid?: string;
  type: number;
  wallet: string | boolean;
}

export interface PaymentResponse {
  http_code: number;
  response: {
    session: string;
    type: number;
  };
  status: OldResponseCodes;
}

export interface TransformedPaymentResponse {
  httpCode: number | null;
  paymentSession: {
    session: string;
    type: number;
  } | null;
}

export interface PaymentDetails {
  key: string;
  type: string;
  optional?: boolean;
}

export interface PaymentMethods {
  brands: PaymentMethodTypes[];
  details: PaymentDetails[];
  name: string;
  type: string;
}

export interface PaymentMethodsResponse {
  http_code: number;
  response: {
    paymentMethods: PaymentMethods;
  };
  status: OldResponseCodes;
}

export interface PaymentMethodsResponseTransformed {
  data:
    | {
        paymentMethods: PaymentMethods;
      }
    | PaymentMethodsResponse;
  meta: FetchBaseQueryMeta | undefined;
}

export interface PipStartRequest {
  membership: string;
  service: string;
}

export interface PipStartResponse {
  status: string;
  session: string;
}

export interface PipData {
  uid: string;
  membership: string;
  startTime: string;
  minutes: number;
  price: number;
  session: string;
  button?: 'stop_in';
}

export enum PipEvents {
  START = 'start',
  STOP = 'stop',
  CLOSED = 'closed',
  PING = 'ping',
  BUTTON_PRESS = 'buttonPress',
  PAYMENT_FAILED = 'payment_failed',
}

export interface PipPingEvent {
  type: PipEvents.PING;
  message: number;
}

export interface PipSocketDataString {
  identifier: string;
  message: string;
}

export interface PipSocketData {
  event: PipEvents;
  data: {
    stopTime: string;
    membership: string;
    orderId: string;
    service: string;
    minutes: string;
    price: string;
    button?: 'stop_in';
  };
}

export function isPingEvent(
  data: PipPingEvent | PipSocketDataString
): data is PipPingEvent {
  return (data as PipPingEvent).type !== undefined;
}

interface AllPagesContent {
  link: string;
  language: {
    slug: string;
  };
}

export interface AllPagesResponse {
  pages: {
    nodes: AllPagesContent[];
  };
}

export enum Languages {
  DA = 'DA',
  NO = 'NO',
  DE = 'DE',
  SE = 'SE',
}
/*
Ignored for now - OLOE
export interface OuterQueryReponse {
    data:       Data;
    extensions: Extensions;
}
*/

export interface GeneralOptionsQuery {
  generalOptions: GeneralOptions;
  page: Page;
}

export interface GeneralOptions {
  options: Options;
}

export interface Options {
  atav: null;
  countryCode: string;
  vatPercentage: number;
  defaultMapPosition: DefaultMapPosition;
  locationsMainPage: LocationsMainPage;
  primaryNavigation: PrimaryNavigation[];
  headerSignupButton: HeaderSignupButton;
  currencyCode: string;
  currencySymbol: string;
  becomeMemberLink: BecomeMemberLinkClass;
  faqHelpPage: null;
  singleWashLink: BecomeMemberLinkClass;
  priceModels: PriceModel[];
  unlimitedWashLink: BecomeMemberLinkClass;
  newsletterModal: NewsletterModal;
  consentText: string;
  consentTextCheckout: string;
  strings: String[];
  campaign: Campaign;
  footer: Footer;
  unsubscribe: Unsubscribe;
}

export interface BecomeMemberLinkClass {
  target: Target;
  title: string;
  url: string;
}

export enum Target {
  Blank = '_blank',
  Empty = '',
}

export interface Campaign {
  isActive: boolean;
  header: string;
  endDate: Date;
  text: string;
  afterPrice: number;
  beforePrice: number;
  link: CampaignLink;
  image: Image;
}

export interface Image {
  altText: string;
  title: string;
  sourceUrl: string;
  placeholder: string;
  mediaDetails: MediaDetails;
}

export interface MediaDetails {
  height: number;
  width: number;
}

export interface CampaignLink {
  url: string;
}

export interface DefaultMapPosition {
  zoom: string;
  latitude: number;
  longitude: number;
}

export interface Footer {
  companyDetails: string;
  socialLinks: SocialLink[];
  sections: MinimalSectionElement[];
  companyDetailsMini: string;
  minimalSections: MinimalSectionElement[];
}

export interface MinimalSectionElement {
  links: PrimaryNavigation[];
  header: null | string;
}

export interface PrimaryNavigation {
  link: BecomeMemberLinkClass;
}

export interface SocialLink {
  link: BecomeMemberLinkClass;
  icon: Icon;
}

export interface Icon {
  altText: string;
  title: string;
  sourceUrl: string;
  placeholder: boolean;
  mediaDetails: null;
}

export interface HeaderSignupButton {
  appearance: string;
  id: null;
  link: BecomeMemberLinkClass;
}

export interface LocationsMainPage {
  uri: string;
}

export interface NewsletterModal {
  header: null;
  checkboxText: null;
  successMessage: string;
  image: null;
  icon: null;
}

export interface PriceModel {
  id: string;
  link: CampaignLink;
  priceWarning: string | null;
  information: Information;
}

export interface Information {
  informationText: null | string;
  informationLink: null;
}

export interface String {
  value: string;
  key: string;
}

export interface Unsubscribe {
  content: string;
  confirmation: string;
  subscriptioncheck: string;
}

export interface Page {
  title: string;
  postType: string;
  supportTopic: boolean;
  template: Template;
  translations: Translation[];
  seo: SEO;
  sections: Sections;
  status: string;
  uri: string;
}

export interface Sections {
  sections: SectionsSection[];
}

export type Features =
  | 'has_arrows'
  | 'max_three_per_row'
  | 'grid_on_mobile'
  | 'hide_text_on_mobile'
  | 'slider'
  | 'max_imagesize';

export interface SectionButton {
  appearance: string;
  href?: string;
  link: {
    url: string;
    title?: string;
    target?: string;
  };
  text?: string | object;
}

export interface SectionLink {
  url?: string;
  title?: string;
  target?: string;
}
export interface SectionsSection {
  backgroundColor: string;
  sectionStyling: null;
  idForLinking: null;
  headerSettings: HeaderSettings;
  content: string;
  buttons: null | SectionButton[];
  image: Image | null;
  header: null | string;
  subheader: null;
  headerPosition: string;
  subtext: null;
  textPosition: string;
  features: null | Features[];
  fieldGroupName: string;
  locationImage: string;
  location: LocationSection;
  maxHeight: string;
  mirrorLength: string;
  name: string;
  openHours: string;
  uid: string;
  wheelWidth: string;
  grandOpening: string;
  heroText: string;
  skipCount: number;
  hallsCount: number;
  includeDefaultComponents: boolean;
  shortName: string;
  serviceUnits: string;
}

export interface HeaderSettings {
  header?: Header;
  subheader?: Subheader;
}

export interface Header {
  tag: string;
  size: string;
}

export interface Subheader {
  tag: string;
}

export interface SEO {
  title: string;
  metaDesc: string;
  metaKeywords: string;
  canonical: string;
  opengraphType: string;
  opengraphTitle: string;
  opengraphUrl: string;
  opengraphSiteName: string;
  opengraphDescription: string;
  opengraphModifiedTime: Date;
  breadcrumbs: Breadcrumb[];
  metaRobotsNoindex: string;
  metaRobotsNofollow: string;
  schema: Schema;
}

export interface Breadcrumb {
  text: string;
  url: string;
}

export interface Schema {
  raw: string;
}

export interface Template {
  id: string;
  templateName: string;
}

export interface Translation {
  link: string;
  language: Language;
}

export interface Language {
  locale: string;
}

export interface Extensions {
  debug: Debug[];
}

export interface Debug {
  type: string;
  message: string;
  field_name: string;
  type_name: string;
  stack: string[];
}

export interface DynamicDictionary {
  [key: string]: string | undefined;
}

export interface ServiceUnits {
  hall: Hall;
  self_wash: MachineType;
  mat_cleaner: MachineType;
  vacuum: MachineType;
  pre_wash: MachineType;
  high_pressure_pre_wash: MachineType;
}

export interface Hall {
  total_count: number;
  machine_type: ServiceUnitType;
}

export interface MachineType {
  total_count: number;
  details: Detail[];
}

export interface Detail {
  uid: string;
  name: string;
  service_unit_model_name: string;
}

export interface WordPressOptions {
  options: {
    [key: string]: string;
  };
}

export interface CheckoutStep1Content {
  checkoutStep1ContentKey: string;
  checkoutStep1HeaderText: string;
  checkoutStep1Description: string;
  checkoutStep1Suffix: string;
}
