import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SingleLocation } from '@shared/types/common';

interface CommonState {
  locations: SingleLocation[];
}

const initialState: CommonState = {
  locations: [],
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations(state, action: PayloadAction<SingleLocation[]>) {
      state.locations = action.payload;
    },
  },
});

export const { setLocations } = locationsSlice.actions;
export const commonReducer = locationsSlice.reducer;

export default locationsSlice.reducer;
