import { setOptions } from '@features/WordPress/store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const SiteOptionsProvider = ({ children, options }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOptions(options));
  }, []);

  return children;
};

export default SiteOptionsProvider;
