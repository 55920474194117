/**
 * Layouts -> Header
 */

import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import throttle from '@utilities/helpers/throttle';
import Container from '@components/Container';
import UserIcon from '@icons/user.svg';
import NoSsr from '@components/NoSsr/NoSsr';

import Link from '@components/Link';
import Button from '@features/PageBuilder/components/Button';
import { useSelector } from 'react-redux';
import { isAuthenticated } from '@features/Auth/store';
import {
  Root,
  IsWithinTop,
  IsTransperant,
  NavUp,
  NavDown,
  ContainerClass,
  NavWrapper,
  Start,
  End,
  LogoWrapper,
  Logo as LogoClass,
  CenterHeader,
  MyAccount,
  Addons,
  SignupButton,
  BurgerMenu,
  MenuOpen,
  Pole,
  UserIcon as UserIconClass,
  NavMenuButtonsMobile,
  PipContainerClass,
} from './Header.module.css';
import Navigation from './components/Navigation';
import { useRouter } from 'next/router';

const SCROLL_DELTA = 5;
const IS_IN_TOP_TRESHOLD = 100;

const Header = ({
  items,
  signupButton,
  userButton,
  centerHeaderComponent,
  isTransperant,
  addons,
}) => {
  const headerRef = useRef(null);
  const headerFocusTimeout = useRef(null);
  const [headerHasFocusWithin, setHeaderHasFocusWithin] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isWithinTop, setIsWithinTop] = useState(true);
  const previousScrollPosition = useRef(0);
  const shouldShowHeader = headerHasFocusWithin || (showHeader && hasScrolled);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { pathname } = useRouter();
  const isPip = pathname.includes('/pip');

  const isAuth = useSelector(isAuthenticated);

  /**
   * When a child element is focused or blurred
   * we check if the focus is within the header and
   * show or hide the header accordingly
   */
  // eslint-disable-next-line no-unused-vars
  const onChildFocusOrBlur = () => {
    clearTimeout(headerFocusTimeout.current);
    headerFocusTimeout.current = setTimeout(() => {
      const hasFocusWithin = headerRef.current.contains(document.activeElement);
      setHeaderHasFocusWithin(hasFocusWithin);
    }, 50);
  };

  const toggleMenu = (e) => {
    if (e) e.preventDefault();

    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${previousScrollPosition.current}px`;
      document.body.style.width = '100%';
      document.body.classList.add('menu-is-open');
    } else {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      document.body.style.removeProperty('width');
      document.body.classList.remove('menu-is-open');
      window.scrollTo(0, previousScrollPosition.current);
    }
  }, [menuIsOpen]);

  useEffect(() => {
    if (showHeader) {
      document.body.classList.add('header-is-shown');
    } else {
      document.body.classList.remove('header-is-shown');
    }
  }, [showHeader]);

  useEffect(
    () => () => {
      clearTimeout(headerFocusTimeout.current);
    },
    []
  );
  const outerHeightOfElement = (el) => {
    try {
      const style = getComputedStyle(el);
      return (
        el.offsetHeight +
        parseInt(style.marginTop, 10) +
        parseInt(style.marginBottom, 10)
      );
    } catch (error) {
      return 0;
    }
  };

  /**
   * Handle header visibility
   */
  const handleHeaderVisibility = () => {
    const siteHeaderHeight = outerHeightOfElement(headerRef.current);
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    const contentEndTopFromScreenTop = document
      .getElementById('s-footer')
      ?.getBoundingClientRect().top;

    // Scroll down
    if (
      currentScrollPosition > previousScrollPosition.current &&
      currentScrollPosition > siteHeaderHeight
    ) {
      const bottomReached =
        contentEndTopFromScreenTop <= window.innerHeight ||
        contentEndTopFromScreenTop <= 0;

      if (bottomReached) {
        if (!showHeader) {
          setShowHeader(true);
        }

        // if bottom is not reached and header is visible
      } else if (showHeader) {
        setShowHeader(false);
      }

      // Scroll up
    } else if (!showHeader) {
      setShowHeader(true);
    }
  };

  const onScrollHandler = () => {
    if (menuIsOpen) {
      return;
    }

    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    const siteHeaderHeight = outerHeightOfElement(headerRef.current);

    // If user has scrolled under limit
    if (currentScrollPosition > IS_IN_TOP_TRESHOLD) {
      setIsWithinTop(false);
    } else {
      setIsWithinTop(true);
    }

    // Only handle header visibility if the user has scrolled more than SCROLL_DELTA
    if (
      Math.abs(previousScrollPosition.current - currentScrollPosition) >=
        SCROLL_DELTA ||
      currentScrollPosition > siteHeaderHeight
    ) {
      if (!hasScrolled) {
        setHasScrolled(true);
      }

      handleHeaderVisibility();
    }

    // Save previous scroll position
    previousScrollPosition.current = currentScrollPosition;
  };

  useEffect(() => {
    const throttledHandler = throttle(onScrollHandler, 250);
    document.addEventListener('scroll', throttledHandler);

    return () => {
      document.removeEventListener('scroll', throttledHandler);
    };
  });
  const menuRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        menuIsOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuIsOpen, menuRef]);

  return (
    <header
      id="s-header"
      className={classnames(Root, {
        [IsWithinTop]: isWithinTop,
        [NavUp]: (hasScrolled || headerHasFocusWithin) && !shouldShowHeader,
        [NavDown]: (hasScrolled || headerHasFocusWithin) && shouldShowHeader,
        [IsTransperant]: isTransperant,
        [MenuOpen]: menuIsOpen,
      })}
      ref={headerRef}
    >
      <Container
        innerRef={menuRef}
        className={isPip ? PipContainerClass : ContainerClass}
      >
        <div className={Start}>
          <Link className={LogoWrapper} href="/">
            <img
              src="/assets/brand/logo.svg"
              alt="logo"
              className={LogoClass}
            />
          </Link>
        </div>

        {centerHeaderComponent && (
          <div className={CenterHeader}>{centerHeaderComponent}</div>
        )}

        {!isPip && (
          <>
            <div className={NavWrapper}>
              {items && (
                <Navigation
                  menuIsOpen={menuIsOpen}
                  onClick={() => setMenuIsOpen(false)}
                  items={items}
                />
              )}
              <div className={NavMenuButtonsMobile}>
                {signupButton && (
                  <Button
                    className={SignupButton}
                    {...signupButton}
                    element={Link}
                    hasArrow={false}
                  />
                )}
              </div>
            </div>

            <div className={End}>
              {addons && addons.length > 0 ? (
                <div className={Addons}>
                  {addons.map((Addon, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{Addon}</div>
                  ))}
                </div>
              ) : null}
              {userButton && (
                <NoSsr>
                  <Button
                    className={MyAccount}
                    element={Link}
                    href={isAuth ? '/my-account/overview' : '/login'}
                    appearance="icon"
                  >
                    <UserIcon className={UserIconClass} />
                  </Button>
                </NoSsr>
              )}
              {items ? (
                <Button
                  className={BurgerMenu}
                  onClick={toggleMenu}
                  element="a"
                  href="#"
                  appearance="icon"
                >
                  <span className={Pole} />
                  <span className={Pole} />
                  <span className={Pole} />
                </Button>
              ) : null}
              {signupButton && (
                <Button
                  className={SignupButton}
                  {...signupButton}
                  element={Link}
                  hasArrow={false}
                />
              )}
            </div>
          </>
        )}
      </Container>
    </header>
  );
};

export default Header;
