export const isProduction = process.env.NODE_ENV === 'production';
export const isStaging = (process.env.NODE_ENV as string) === 'staging';
export const isDevelopment = !isProduction;

interface GTMValues {
  [index: string]: string | undefined;
}

export const GTM: GTMValues = {
  da: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  se: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_SE,
  no: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_NO,
  de: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID_DE,
};
