import { useEffect, useRef } from 'react';
import { NextRouter, useRouter } from 'next/router';
import resolveLocalPathFromURL from '@utilities/helpers/resolve-local-path-from-url';
import classNames from 'classnames';
import buttonStyles from '@components/Button/Button.module.css';
import styles from './HTMLText.module.css';
const {
  Root: ButtonClass,
  IsPrimary: IsPrimaryButtonClass,
  IsSecondary: IsSecondaryButtonClass,
  Medium: MediumButtonClass,
  CheckmarkList,
} = buttonStyles;

const { WithFormatting, ContentButton } = styles;

const dynamicContentLinksEventHandler = (e: any, router: NextRouter) => {
  e.preventDefault();
  try {
    router.push(resolveLocalPathFromURL(e.target?.closest('a')?.href));
  } catch (error) {
    router.push(resolveLocalPathFromURL(e.target?.href));
  }
};

interface Props {
  className?: string;
  children: React.ReactNode;
  withFormatting?: boolean;
  checkmarkList?: boolean;
}

const HTMLText = ({
  className,
  children,
  withFormatting = true,
  checkmarkList = false,
}: Props) => {
  const element = useRef<null | HTMLDivElement>(null);
  const router = useRouter();

  const classes = classNames(
    {
      [WithFormatting]: withFormatting,
      [CheckmarkList]: checkmarkList,
    },
    className
  );

  const replaceContent = (content: React.ReactNode): string | React.ReactNode =>
    typeof content === 'string'
      ? content
          .toString()
          .replace(
            new RegExp('EditorStyled-Button', 'g'),
            `${ContentButton} ${ButtonClass} ${MediumButtonClass}`
          )
          .replace(
            new RegExp('EditorStyled-IsPrimary', 'g'),
            IsPrimaryButtonClass
          )
          .replace(
            new RegExp('EditorStyled-IsSecondary', 'g'),
            IsSecondaryButtonClass
          )
      : content;
  const dangerouslySetInnerHTML = children
    ? { dangerouslySetInnerHTML: { __html: replaceContent(children) } }
    : {};

  useEffect(() => {
    if (
      children &&
      typeof children === 'string' &&
      children.includes('<a') &&
      element &&
      element.current
    ) {
      const linkElements = element.current.getElementsByTagName('a');
      for (const link of linkElements) {
        if (!link.classList.contains('ex-h')) {
          if (link.target !== '_blank') {
            link.addEventListener('click', (e) =>
              dynamicContentLinksEventHandler(e, router)
            );
          }
          link.classList.add('ex-h');
        }
      }
    }
  }, [children, router]);

  if (typeof children === 'string') {
    return (
      <div
        className={classes}
        ref={element}
        {...(dangerouslySetInnerHTML as any)}
      />
    );
  }
  return (
    <div className={classes} ref={element}>
      {replaceContent(children)}
    </div>
  );
};

export default HTMLText;
