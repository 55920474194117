import { mapLocaleCodeToCountry } from '@features/WordPress/utilities/helpers/locale';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import router from 'next/router';

export default fetchBaseQuery({
  baseUrl: `${process.env.USER_API_ENDPOINT}/`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      // headers.set('authorization', `Bearer ${token}`);
      headers.set('authorization', token);
    }

    // assume JSON
    headers.set('Content-Type', 'application/json');
    headers.set('accept', '*/*');

    headers.set('apilang', mapLocaleCodeToCountry(router.locale));

    return headers;
  },
});
