export const transformResponse = (response) => response?.response || response;

export const responseHandler = async (response) => {
  const data = await response.json();
  const { headers } = response;

  return {
    data,
    headers,
  };
};
