/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetchingBrowserPosition: false,
  ipInfo: {},
  browserCoordinates: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsFetchingBrowserPosition(state, { payload }) {
      state.isFetchingBrowserPosition = payload;
    },
    setIpInfo(state, { payload }) {
      state.ipInfo = payload;
    },
    setBrowserCoordinates(state, { payload }) {
      state.browserCoordinates = payload;
      state.isFetchingBrowserPosition = false;
    },
  },
});

export const {
  setIsFetchingBrowserPosition,
  setIpInfo,
  setBrowserCoordinates,
} = appSlice.actions;

export default appSlice.reducer;
