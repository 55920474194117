/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { useState } from 'react';
import resolveLocalPathFromURL from '@utilities/helpers/resolve-local-path-from-url';
import { Root } from './Link.module.css';

const Link = ({
  className,
  activeClassName,
  hoverClassName,
  href,
  children,
  as,
  exact,
  target,
  external,
  ...props
}) => {
  const { pathname, asPath } = useRouter();
  const localPath = external ? href : resolveLocalPathFromURL(href);
  const [isHovered, setIsHovered] = useState(false);
  const activateHover = () => setIsHovered(true);
  const removeHover = () => setIsHovered(false);
  const realAs = as || localPath;

  const classes = classnames(
    Root,
    {
      [activeClassName]:
        (localPath && localPath.toString().trim() === pathname.trim()) ||
        asPath === realAs ||
        (asPath.startsWith(realAs) && !exact),
      [hoverClassName]: isHovered,
    },
    className
  );

  const finalHref = localPath || href;
  const innerLink = (
    <a
      target={target}
      href={finalHref}
      onMouseOver={hoverClassName ? activateHover : null}
      onFocus={hoverClassName ? activateHover : null}
      onMouseLeave={hoverClassName ? removeHover : null}
      onBlur={hoverClassName ? removeHover : null}
      className={classes}
      {...props}
    >
      {children}
    </a>
  );

  if (finalHref && finalHref === '#') {
    return innerLink;
  }

  return (
    <NextLink href={finalHref} prefetch={false} legacyBehavior>
      {innerLink}
    </NextLink>
  );
};

Link.defaultProps = {
  className: '',
  exact: false,
  target: '',
  href: '',
  external: false,
};

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  exact: PropTypes.bool,
  external: PropTypes.bool,
};

export default Link;
