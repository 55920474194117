/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { pipApi } from '../services/pip';
import { PipEvents } from '@shared/types';
import {
  LicensePlateData,
  MembershipsData,
  PIPFlowSteps,
  PipData,
  SelectedServiceUnit,
  ServiceStates,
} from '@shared/types/pip';
import { ServiceUnitType } from '@shared/types/common';

interface InitialState {
  serviceId: string;
  serviceStatus: number;
  serviceIsLoading: boolean;
  serviceTimeCounter: number;
  servicePriceCounter: number;
  serviceSession: string;
  serviceFinished: boolean;
  serviceEvent: {
    event: PipEvents;
    data: PipData;
  };
  serviceUnit: string;
  selectedLp?: string;
  selectedServiceUnit: SelectedServiceUnit | undefined;
  pipStep?: PIPFlowSteps;
  serviceMessageModal: boolean;
  isAuthenticated?: boolean;
  addNewCarModal: boolean;
  pipFlow?: ServiceUnitType | undefined;
  membership?: MembershipsData;
  memberships?: MembershipsData[];
  licensePlates?: LicensePlateData[];
  isLoadingOrFetching?: boolean;
}

const initialState: InitialState = {
  serviceId: '',
  serviceStatus: ServiceStates.IS_AVAILABLE,
  serviceIsLoading: true,
  serviceTimeCounter: 0,
  servicePriceCounter: 0,
  serviceSession: '',
  serviceFinished: false,
  serviceEvent: {
    event: PipEvents.PING,
    data: {} as PipData,
  },
  serviceUnit: '',
  selectedLp: undefined,
  selectedServiceUnit: undefined,
  pipStep: PIPFlowSteps.AUTHENTICATION,
  serviceMessageModal: false,
  isAuthenticated: false,
  addNewCarModal: false,
  membership: undefined,
  isLoadingOrFetching: false,
};

const pipSlice = createSlice({
  name: 'pip',
  initialState,
  reducers: {
    setServiceId(state, { payload }) {
      state.serviceId = payload || '';
    },
    setPipStep(state, { payload }) {
      state.pipStep = payload || '';
    },
    setServiceMessageModal(state, { payload }) {
      state.serviceMessageModal = payload || false;
    },
    setIsAuthenticated(state, { payload }) {
      state.isAuthenticated = payload || false;
    },
    setSelectedLicensePlate(state, { payload }) {
      state.selectedLp = payload || undefined;
    },
    setSelectedServiceUnit(state, { payload }) {
      state.selectedServiceUnit = payload || '';
    },
    setAddNewCarModal(state, { payload }) {
      state.addNewCarModal = payload || false;
    },
    setServiceStatus(state, { payload }) {
      state.serviceStatus = payload || ServiceStates.IS_AVAILABLE;
      if (payload === 2) {
        // IS_WASHING
        state.serviceIsLoading = false;
        state.serviceFinished = false;
        state.servicePriceCounter = 0;
        state.serviceTimeCounter = 0;
      }
    },
    updateTimePriceCounter(
      state,
      { payload }: PayloadAction<{ time: number; price: number }>
    ) {
      state.serviceTimeCounter = Number(payload.time);
      state.servicePriceCounter = Number(payload.price);
    },
    setServiceSession(state, { payload }) {
      state.serviceSession = payload || '';
    },
    setServiceToAvailable(
      state,
      { payload }: PayloadAction<{ time: number; price: number }>
    ) {
      state.serviceTimeCounter = Number(payload.time);
      state.servicePriceCounter = Number(payload.price);
      state.serviceSession = '';
      state.serviceFinished = true;
      state.serviceIsLoading = false;
    },
    setServiceIsLoading(state, { payload }) {
      state.serviceIsLoading = payload;
    },
    setServiceEvent(state, { payload }) {
      state.serviceEvent = payload;
    },
    resetService(state, { payload: { licensePlate, ...payload } }) {
      const { serviceId, serviceIsLoading } = state;
      Object.assign(state, initialState);
      state.serviceId = serviceId;
      state.serviceIsLoading = serviceIsLoading;
      state.servicePriceCounter = 0;
      state.serviceTimeCounter = 0;
      state.pipStep = PIPFlowSteps.WASHING;
      state.serviceStatus = ServiceStates.IS_AVAILABLE;
      state.selectedServiceUnit = payload;
      state.selectedLp = licensePlate;
    },
    setPipFlow(state, { payload }) {
      state.pipFlow = payload;
    },
    setMembership(state, { payload }) {
      state.membership = payload;
    },
    setMemberships(state, { payload }) {
      state.memberships = payload;
    },
    setLicensePlates(state, { payload }) {
      state.licensePlates = payload;
    },
    setIsLoadingOrFetching(state, { payload }) {
      state.isLoadingOrFetching = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          pipApi.endpoints.checkService.matchFulfilled,
          pipApi.endpoints.checkService.matchRejected
        ),
        (state, { payload }) => {
          if (!payload) return;

          state.serviceIsLoading = false;
          state.serviceUnit = (payload as any)?.serviceUnit || '';

          const apiResponse = payload as {
            status: number;
            data: PipData;
            event?: PipEvents;
          };

          switch (apiResponse.status) {
            case 502:
              state.serviceStatus = ServiceStates.IS_CLOSED;
              state.serviceIsLoading = false;
              break;
            case 503:
              if (apiResponse.data.outOfOrder) {
                state.serviceStatus = ServiceStates.IS_CLOSED;
                state.selectedServiceUnit = apiResponse.data;
                state.pipFlow = apiResponse.data.type;
              } else {
                if (apiResponse.data) {
                  const newPipStep = state.isAuthenticated
                    ? PIPFlowSteps.WASHING
                    : PIPFlowSteps.AUTHENTICATION;

                  state.selectedServiceUnit = apiResponse.data;
                  state.pipStep = newPipStep;
                  state.serviceSession = apiResponse.data.session;
                  state.pipFlow = apiResponse.data.type;

                  setServiceToAvailable({
                    time: Number((apiResponse.data as PipData)?.minutes) * 60,
                    price: Number((apiResponse.data as PipData)?.price),
                  });
                }

                state.serviceStatus = ServiceStates.IS_BUSY;
                state.serviceIsLoading = false;
                break;
              }
          }
        }
      )
      .addMatcher(
        isAnyOf(
          pipApi.endpoints.startService.matchPending,
          pipApi.endpoints.stopService.matchPending
        ),
        (state) => {
          state.serviceIsLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          pipApi.endpoints.startService.matchFulfilled,
          pipApi.endpoints.startService.matchRejected,
          pipApi.endpoints.stopService.matchFulfilled,
          pipApi.endpoints.stopService.matchRejected
        ),
        (state, { payload }) => {
          if (payload.status === 400) {
            state.serviceIsLoading = false;
          } else {
            switch (payload.status) {
              case 502:
                state.serviceStatus = ServiceStates.IS_CLOSED;
                state.serviceIsLoading = false;
                break;
              case 503:
                state.serviceStatus = ServiceStates.IS_BUSY;
                state.serviceIsLoading = false;
                break;
              default:
                state.servicePriceCounter = 0;
                state.serviceTimeCounter = 0;
                break;
            }
          }
        }
      );
  },
});

export const {
  setServiceId,
  setPipStep,
  setServiceMessageModal,
  setIsAuthenticated,
  setSelectedLicensePlate,
  setSelectedServiceUnit,
  setAddNewCarModal,
  setServiceStatus,
  updateTimePriceCounter,
  setServiceSession,
  setServiceIsLoading,
  setServiceEvent,
  resetService,
  setServiceToAvailable,
  setPipFlow,
  setMembership,
  setMemberships,
  setLicensePlates,
  setIsLoadingOrFetching,
} = pipSlice.actions;

export default pipSlice.reducer;
