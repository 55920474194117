/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { redeemApi } from '../services/redeem';

const initialState = {
  /**
   * Voucher code for single wash
   */
  voucherCode: '',
  /**
   * Is voucher code valid
   */
  isValidVoucherCode: false,
  /**
   * Voucher info
   */
  voucherInfo: {},
  /**
   * License plate
   */
  licensePlate: '',
};

const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    setVoucherCode(state, { payload }) {
      state.voucherCode = payload || null;
    },

    setIsValidVoucherCode(state, { payload }) {
      state.isValidVoucherCode = payload;
    },

    setLicensePlate(state, { payload }) {
      state.licensePlate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(redeemApi.endpoints.checkCode.matchRejected, (state) => {
      state.isValidVoucherCode = false;
    });
    builder.addMatcher(
      redeemApi.endpoints.checkCode.matchFulfilled,
      (state, { payload }) => {
        state.voucherInfo = payload;
      }
    );
    builder.addMatcher(
      redeemApi.endpoints.redeemCode.matchFulfilled,
      (state) => {
        state.licensePlate = '';
      }
    );
  },
});

export const { setIsValidVoucherCode, setVoucherCode, setLicensePlate } =
  redeemSlice.actions;

export default redeemSlice.reducer;
