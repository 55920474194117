import Button from '@components/Button';
import Link from '@components/Link';

const ExtendedButton = ({ appearance, link, children, id, ...props }) => (
  <Button
    element={Link}
    appearance={appearance}
    href={link?.url}
    {...(id && {
      'data-id': id,
    })}
    {...props}
  >
    {children || link?.title}
  </Button>
);

export default ExtendedButton;
