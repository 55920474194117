import { isRejectedWithValue } from '@reduxjs/toolkit';
import removeSensitiveData from '@utilities/helpers/remove-sensitive-data';

/**
 * Log a warning and show a toast!
 */
// eslint-disable-next-line no-unused-vars
export const rtkQueryFailed = (_api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!

  if (isRejectedWithValue(action)) {
    console.warn(
      'A query failed',
      JSON.stringify(removeSensitiveData(action), null, 4)
    );
    // toast.warn({ title: 'Async error!', message: action.error.data.message })
  }

  return next(action);
};
