/* eslint-disable no-param-reassign */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import auth from '@features/Auth/store';
import checkoutReducer from '@features/Checkout/store';
import wordpress from '@features/WordPress/store';
import redeemReducer from '@features/Redeem/store';
import pip from '@features/Pip/store';
import cancellation from '@features/Cancellation/store';
import common from './common';
import { authApi } from '@features/Auth/services/auth';
import { userApi } from '@shared/services/users';
import { locationApi } from '@features/Location/services/locations';
import { checkoutApi } from '@features/Checkout/services/checkout';
import { commonApi } from '@shared/services/common';
import { membershipApi } from '@features/Membership/services/memberships';
import { wordpressApi } from '@features/WordPress/services/wordpress';
import persistStore from 'redux-persist/lib/persistStore';
import { redeemApi } from '@features/Redeem/services/redeem';
import { pipApi } from '@features/Pip/services/pip';
import { isDevelopment, isProduction } from '@utilities/environment';
import app from './app';
import { rtkQueryFailed } from './middleware/rtkQueryFailed';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import locations from './locations';

// sessionStorage
const checkoutConfig = {
  key: 'washworld-checkout',
  storage: storageSession,
  version: 1,
};
const redeemConfig = {
  key: 'washworld-redeem',
  storage: storageSession,
  version: 1,
};

// localStorage
const rootPersistConfig = {
  key: 'washworld-root',
  storage,
  version: 8, // update this when critical stuff changes
  whitelist: ['auth', authApi.reducerPath],
};

const rootReducer = combineReducers({
  app,
  auth,
  checkout: persistReducer(checkoutConfig, checkoutReducer),
  redeem: persistReducer(redeemConfig, redeemReducer),
  wordpress,
  pip,
  cancellation,
  common,
  locations,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [checkoutApi.reducerPath]: checkoutApi.reducer,
  [wordpressApi.reducerPath]: wordpressApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [membershipApi.reducerPath]: membershipApi.reducer,
  [redeemApi.reducerPath]: redeemApi.reducer,
  [pipApi.reducerPath]: pipApi.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: !isProduction || isDevelopment,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  // middleware: [rtkQueryFailed, authApi.middleware, authApi.middleware, userApi.middleware],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(rtkQueryFailed)
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(checkoutApi.middleware)
      .concat(wordpressApi.middleware)
      .concat(locationApi.middleware)
      .concat(commonApi.middleware)
      .concat(membershipApi.middleware)
      .concat(redeemApi.middleware)
      .concat(pipApi.middleware),
});

/* Typesafe redux selectors */
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store, {}, () => {
  persistor.persist();
});

export default store;
