/* eslint-disable no-param-reassign */
import { redeemApi } from '@features/Redeem/services/redeem';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { userApi } from '@shared/services/users';
import { authApi } from '../services/auth';

const initialState = {
  /**
   * The AUTH token used to authenticate against the API
   */
  token: null,

  /**
   * The inteded URL to redirect user back to after
   * login (if user tried to access an page that
   * required authentication)
   */
  intended: null,

  /**
   * Logging in as user? (MasqueradeUser)
   *
   * null = initial
   * true = loading
   * false = something went wrong
   */
  loggingInAsUser: null,

  /**
   * When logging in as another user,
   * the initial users token will be stored here (if any)
   * so he can easily log back in to personal account
   */
  savedAuthToken: null,

  /**
   * When logged in as another user, this will be true
   */
  isMasquerade: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut(state) {
      state.token = null;
    },

    setIntended(state, { payload }) {
      state.intended = payload;
    },

    attemptLoginAsUser(state) {
      state.isMasquerade = false;
      state.loggingInAsUser = true;
      state.savedAuthToken = state.savedAuthToken || state.token;
    },

    failedMasqueradeLogin(state) {
      state.loggingInAsUser = false;
      state.savedAuthToken = null;
    },

    setMasqueradeUser(state, { payload: token }) {
      state.isMasquerade = true;
      state.loggingInAsUser = null;
      state.token = token;
    },

    logOutFromMasquerade(state) {
      // save the original token
      const originalToken = JSON.parse(JSON.stringify(state.savedAuthToken));

      // reset the state
      Object.assign(state, initialState);

      // set the original token (if any)
      state.token = originalToken;
    },
  },
  extraReducers: (builder) => {
    // store token on login
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token;
      }
    );

    // store auth/bearer token on signup
    builder.addMatcher(
      userApi.endpoints.createUser.matchFulfilled,
      (state, { payload: { token } }) => {
        state.token = token.replace('Bearer ', '');
      }
    );

    // reset state when user logged out
    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.logout.matchFulfilled
        // userApi.endpoints.getMe.matchRejected
      ),
      (state) => {
        Object.assign(state, initialState);
      }
    );

    // log user out if fetch me failed due ot invalid token
    builder.addMatcher(
      userApi.endpoints.getMe.matchRejected,
      (state, { payload }) => {
        if (payload?.data?.message?.includes('No valid token')) {
          Object.assign(state, initialState);
        }
      }
    );

    // When new user redeems voucher their token gets stored.
    builder.addMatcher(
      redeemApi.endpoints.redeemCode.matchFulfilled,
      (state, { payload: { token } }) => {
        if (token) {
          state.token = token.replace('Bearer ', '');
        }
      }
    );
  },
});

export const {
  setIntended,
  attemptLoginAsUser,
  setMasqueradeUser,
  failedMasqueradeLogin,
  logOutFromMasquerade,
  ...authActions
} = authSlice.actions;
export const isAuthenticated = (state) => !!state.auth.token;

export default authSlice.reducer;
