import ConditionalWrapper from '@components/ConditionalWrapper';
import { captureMessage } from '@sentry/nextjs';
import classNames from 'classnames';
import SpinnerIcon from '@assets/spinner.svg';
import Button from '@components/Button';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { insertURLParam } from '@utilities/helpers/common';
import { useTranslation } from 'next-i18next';
import styles from './LoadingIcon.module.css';
const {
  Root,
  IsWhite,
  Wrapper,
  IsLarge,
  HasThickStroke,
  TooLongWaitMessage,
  Button: ButtonClass,
} = styles;
interface Props {
  className?: string;
  color?: string;
  withWrapper?: boolean;
  size?: "default" | "large";
  thickStroke?: boolean;
  maxWait?: number;
  noMaxWait?: boolean;
  noReloadButton?: boolean;
}

const LoadingIcon = ({
  className,
  color,
  withWrapper = false,
  size = "default",
  thickStroke = false,
  maxWait = 4000,
  noMaxWait = false,
  noReloadButton = false,
}: Props) => {
  const { t } = useTranslation(['account', 'common']);
  const [waitedTooLong, setWaitedTooLong] = useState<boolean | number>(false);
  const router = useRouter();

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    if (noMaxWait || !process.browser) {
      return;
    }

    timeouts.push(
      setTimeout(() => {
        setWaitedTooLong(1);

        timeouts.push(
          setTimeout(() => {
            setWaitedTooLong(2);

            if (router.query.didReload) {
              captureMessage('Too long wait');
            }
          }, maxWait * 0.8)
        );
      }, maxWait)
    );

    return () => timeouts.map((timeout) => clearTimeout(timeout));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showReloadButton = !noReloadButton && waitedTooLong === 2;

  return (
    <>
      <ConditionalWrapper
        condition={withWrapper}
        wrapper={(children) => <div className={Wrapper}>{children}</div>}
      >
        <div
          className={classNames([
            Root,
            {
              [IsWhite]: color === 'white',
              [IsLarge]: size === 'large',
              [HasThickStroke]: thickStroke,
            },
            className,
          ])}
        >
          <SpinnerIcon />
        </div>
      </ConditionalWrapper>

      {waitedTooLong && !noMaxWait && (
        <div className={TooLongWaitMessage}>
          <p>
            {t('waitTime', { ns: 'common' })}...
            {showReloadButton && <> {t('reRenderPage', { ns: 'common' })}?</>}
          </p>
          {showReloadButton && (
            <Button
              className={ButtonClass}
              hasArrow={false}
              onClick={() => insertURLParam('didReload', '1')}
            >
              {t('reRenderPage', { ns: 'common' })}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default LoadingIcon;
