/* eslint-disable no-empty */
import Redactyl from 'redactyl.js';

const redactyl = new Redactyl({
  properties: ['password', 'apiKey'], // add sensitive data here
});

const removeSensitiveData = (data) => {
  try {
    return redactyl.redact(data || {});
  } catch (error) {}

  return data;
};

export default removeSensitiveData;
