/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { WordPressOptions } from '@shared/types';

const initialState: WordPressOptions = {
  /**
   * The wordpress options page
   */
  options: {},
};

const wordpressSlice = createSlice({
  name: 'wordpress',
  initialState,
  reducers: {
    setOptions(state, { payload }) {
      state.options = payload;
    },
  },
});

export const { setOptions } = wordpressSlice.actions;

export default wordpressSlice.reducer;
