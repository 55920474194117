/**
 * Takes a link as input and returns only the path of the link, if the link is internal (i.e. used for the Next.js application)
 *
 * @param   {string}  link  A URL (e.g. https://somenext.js/link) or (https://someexternallink.com/some-path)
 *
 * @return  {string}        Pathname or full link. Will return "/link" if internal link, or the full link (https://someexternallink.com/some-path)
 */
const resolveLocalPathFromURL = (link) => {
  let href = link || '#';

  if (href && !href.startsWith('#') && !['/', '#'].includes(href)) {
    try {
      const url = new URL(href);
      if (
        url.host.includes(new URL(process.env.API_CLIENT_ENDPOINT).host) ||
        url.host.includes(new URL(process.env.WORDPRESS_URL).host) ||
        url.host.includes(new URL(process.env.NEXT_BASE_URL).host)
      ) {
        if (url.search.includes('single')) {
          return href;
        }
        href = url.pathname + url.hash;

        if (href !== '/') {
          href = href.replace(/\/+$/, '');
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  return href;
};

export default resolveLocalPathFromURL;
