import classnames from 'classnames';
import styles from './DefaultLayout.module.css';
const { Root, HasBlackFadedHero, PageContent, HasSidebar, NoFooter } = styles;

import Header from './components/Header';
import Footer from './components/Footer';
import {
  Footer as IFooter,
  HeaderSignupButton,
  PrimaryNavigation,
  Template,
  UserMe,
} from '@shared/types';
import { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DefaultSnackbar from '@components/Snackbar/defaultSnackbar';
import { useGetMeQuery } from '@shared/services/users';
import { isAuthenticated } from '@features/Auth/store';
import { useRouter } from 'next/router';

interface DefaultLayoutProps {
  children: any;
  hasBlackFadedHero: boolean;
  noFooter: boolean;
  noPrimaryMenuItems: boolean;
  noSignupButton: boolean;
  noUserButton: boolean;
  menuAddons: Element[];
  minimalFooter: boolean;
  primaryNavigation: PrimaryNavigation;
  headerSignupButton: HeaderSignupButton;
  footer: IFooter;
  template: Template | string;
  centerHeaderComponent: Element;
  noHeader: boolean;
}
const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  primaryNavigation,
  headerSignupButton,
  hasBlackFadedHero = false,
  footer,
  template,
  noFooter = false,
  noPrimaryMenuItems = false,
  noSignupButton = false,
  noUserButton = false,
  centerHeaderComponent,
  menuAddons = [],
  minimalFooter = false,
  noHeader = false,
}) => {
  const { asPath } = useRouter();
  const { snackbarData } = useSelector((state) => state.common);

  const { data: userData, isLoading: isLoggingIn } = useGetMeQuery(null, {
    skip: Boolean(!isAuthenticated),
  });

  const handleAnalyticsCAPI = (user: UserMe) => {
    const {
      uid: userUid,
      email: userEmail,
      firstname: userFirstName,
      lastname: userLastName,
      zipcode: userZipCode,
      city: userCity,
    } = user;

    const userPhone = user.phone
      ? `+${user.phone_prefix}${user.phone}`
      : undefined;

    window?.dataLayer?.push({
      userid: userUid,
      ...(userEmail && { email: userEmail }),
      ...(userPhone && { phone: userPhone }),
      ...(userFirstName && { first_name: userFirstName }),
      ...(userLastName && { last_name: userLastName }),
      ...(userZipCode && { zip_code: userZipCode }),
      ...(userCity && { city: userCity }),
    });
  };

  useEffect(() => {
    if (!userData || isLoggingIn || !asPath) return;

    handleAnalyticsCAPI(userData);
  }, [userData, isLoggingIn, asPath]);

  const classes = classnames(Root, {
    [HasBlackFadedHero]: hasBlackFadedHero,
    [NoFooter]: noFooter,
  });

  const pageContentClasses = classnames(PageContent, {
    [HasSidebar]: template === 'Template_HelpCategoryPage',
  });

  const displaySnackbar =
    snackbarData && snackbarData.message && snackbarData.message !== '';

  return (
    <div className={classes}>
      {displaySnackbar && (
        <DefaultSnackbar
          isOpen={displaySnackbar}
          message={snackbarData.message}
        />
      )}

      {!noHeader && (
        <Header
          items={!noPrimaryMenuItems && primaryNavigation}
          isTransperant={hasBlackFadedHero}
          signupButton={!noSignupButton && headerSignupButton}
          centerHeaderComponent={centerHeaderComponent}
          userButton={!noUserButton}
          addons={menuAddons}
        />
      )}
      <div className={pageContentClasses}>{children}</div>
      {!noFooter && <Footer {...footer} minimalFooter={minimalFooter} />}
    </div>
  );
};

export const getLayout = (page: ReactElement, props: DefaultLayoutProps) => (
  <DefaultLayout {...props}>{page}</DefaultLayout>
);

export default DefaultLayout;
