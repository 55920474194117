import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export default fetchBaseQuery({
  baseUrl: `${process.env.API_CLIENT_ENDPOINT}/wp-json/ww/v1/`,
  prepareHeaders: (headers) =>
    // assume JSON
    // headers.set('Content-Type', 'application/json;charset=UTF-8');
    // headers.set('accept', 'application/json, text/plain,  */*');

    headers,
});
