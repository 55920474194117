import PropTypes from 'prop-types';
import classnames from 'classnames';

import Container from '@components/Container';
import HTMLText from '@features/PageBuilder/components/HTMLText';
import Button from '@features/PageBuilder/components/Button';
import WPImage from '@features/WordPress/components/WPImage';
import { isMobileBelowWidth } from '@utilities/consts';

import Logo from '@assets/brand/logo.svg';

import Link from '@components/Link';
import useWindowSize from '@hooks/use-window-size';

import {
  Root,
  MobileLogo,
  Logo as LogoClass,
  Sections,
  Section,
  SectionHeader,
  SectionLink,
  CompanyDetails,
  SocialIcon,
  MinimalFooter,
  CompanyDetailsMini,
} from './Footer.module.css';

const Footer = ({
  className,
  companyDetails,
  socialLinks,
  sections,
  minimalFooter = false,
  minimalSections,
  companyDetailsMini,
}) => {
  const classes = classnames(Root, className);
  const footerSections = minimalFooter ? minimalSections : sections;

  const windowSize = useWindowSize();

  const isMobile = windowSize.width < isMobileBelowWidth;

  const SectionsClass = classnames(Sections, {
    [MinimalFooter]: minimalFooter,
  });

  const companyDetailsList = companyDetailsMini
    ?.split(';')
    .map((details) => details.trim());

  if (minimalFooter && !isMobile) {
    const [{ links: miniFooterLinks }] = footerSections;

    return (
      <div id="s-footer" className={classes}>
        <Container>
          <div className={SectionsClass}>
            <div className={Section}>
              {miniFooterLinks.map(({ link }) => (
                <div className={SectionLink} key={link.url}>
                  <Link href={link.url} target={link.target}>
                    {link.title}
                  </Link>
                </div>
              ))}
            </div>
            <div className={Section}>
              {companyDetailsList.map((detail) => (
                <div key={detail} className={CompanyDetailsMini}>
                  {detail}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div id="s-footer" className={classes}>
      <Container>
        <div className={SectionsClass}>
          <div className={Section}>
            {!isMobile && <Logo className={LogoClass} />}
            <Logo className={[LogoClass, MobileLogo].join(' ')} />
            <HTMLText withFormatting={false} className={CompanyDetails}>
              {companyDetails}
            </HTMLText>

            {socialLinks &&
              socialLinks.map(({ icon, link }, index) => (
                <Button
                  key={JSON.stringify([link.title], index)}
                  className={SocialIcon}
                  appearance="icon"
                  element={Link}
                  href={link.url}
                >
                  <WPImage layout="intrinsic" {...icon} />
                </Button>
              ))}
          </div>

          {footerSections &&
            footerSections.map(({ header, links }, index) => (
              <div key={JSON.stringify(header, index)} className={Section}>
                {!minimalFooter && <h3 className={SectionHeader}>{header}</h3>}
                <ul>
                  {links.map(({ link }, linkIndex) => (
                    <li
                      key={JSON.stringify([link.title, linkIndex])}
                      className={SectionLink}
                    >
                      <Link href={link.url} target={link.target}>
                        {link.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
  // children: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node,
  // ]).isRequired,
};

export default Footer;
