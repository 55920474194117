import { Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setSnackbarData } from '@shared/store/common';

interface DefaultSnackbarProps {
  isOpen: boolean;
  message: string;
  autoHideDuration?: number;
}

const DefaultSnackbar = ({
  isOpen,
  message,
  autoHideDuration,
}: DefaultSnackbarProps) => {
  const dispatch = useDispatch();

  const handleHideSnackbar = () => {
    dispatch(setSnackbarData(undefined));
  };

  const closeAction = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleHideSnackbar}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleHideSnackbar}
      message={message}
      action={closeAction}
    />
  );
};

export default DefaultSnackbar;
