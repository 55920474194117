export enum CancellationSteps {
  STEP1 = 'step1',
  STEP2 = 'step2',
  STEP3 = 'step3',
}

export interface Plan {
  uid: string;
  currency: string;
  expiration_date?: string | null;
  is_addon: boolean;
  is_expiring: boolean;
  is_new_plan: boolean;
  name: string;
  start_date: string;
}

export interface Subscriptions {
  features: Features[];
  id: string;
  information: Information;
  interval: string;
  is_popular: boolean;
  link: Link;
  price: number;
  price_warning: string;
  name: string;
  short_description: string;
  single_was_price: number;
}

export interface Features {
  name: string;
  enabled: boolean;
}

export interface Information {
  informationlink: string | null;
  informationtext: string;
}

export interface Link {
  target: string;
  title: string;
  url: string;
}

export interface CancellationElement {
  cancellationElementsKey: string;
  cancellationElementsHeader: string;
  cancellationElementsContent: string;
  cancellationElementsColor: string | null;
  cancellationElementsSinglewash: string | null;
}

export enum CancellationElements {
  PAUSE = 'pause',
  CHANGE = 'change',
  CANCELSINGLEWASH = 'cancelsinglewash',
  CANCEL = 'cancel',
  BACK = 'back',
  CUSTOMERSERVICE = 'customerservice',
  KEEPSINGLEWASH = 'keepsinglewash',
}

export interface CancellationReasonsType {
  cancellationReasonKey: string;
  cancellationReason: string;
  feedbackTitle: string;
  winbackElement: string;
}

export interface UserSelection {
  reason: CancellationReasonsType | null;
  reasonFeedback?: string;
  keepSingleWash: boolean;
  expirationDate?: string;
  marketingConsentChanged?: boolean;
}

export enum WinbackModalVariants {
  DARK = 'Dark',
  LIGHT = 'Light',
  ORANGE = 'Orange',
}

export enum PlanName {
  SWPOSTPAYMENT = 'Enkeltvask post payment',
}

export enum MembershipPlans {
  BASIS = 'Plans5c2dc26da0e42',
}
