import { createApi } from '@reduxjs/toolkit/query/react';
import fetchBaseQuery from '@utilities/fetchBaseQuery';
import { transformResponse } from '@utilities/api';

export const redeemApi = createApi({
  reducerPath: 'redeemApi',
  baseQuery: fetchBaseQuery,
  endpoints: (builder) => ({
    /**
     * Check voucher code
     */
    checkCode: builder.query({
      query: (id) => `campaigns/sw/${id}`,
      transformResponse,
    }),

    /**
     * Redeem code
     */
    redeemCode: builder.mutation({
      query: (body) => ({
        url: 'campaigns/sw/redeem',
        method: 'POST',
        body,
      }),
      transformResponse,
    }),
  }),
});

export const { useCheckCodeQuery, useRedeemCodeMutation } = redeemApi;
