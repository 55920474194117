import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children || null;

ConditionalWrapper.defaultProps = {
  condition: '',
};

ConditionalWrapper.propTypes = {
  condition: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

export default ConditionalWrapper;

/**
 * Example:
 * 
 *           <ConditionalWrapper
                condition={hasSlider && boxes.length > 0}
                wrapper={(children) => (
                  <Slider {...sliderSettings}>{children}</Slider>
                )}
              >
                  <div>Some children, with or without Slider</div>
                  <div>Some children, with or without Slider</div>
                  <div>Some children, with or without Slider</div>
              </ConditionalWrapper>
 */
