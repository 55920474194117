/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { commonApi } from '@shared/services/common';
import { userApi } from '@shared/services/users';
import { checkoutApi } from '../services/checkout';

const initialState = {
  isLoading: true,
  formIsValidating: false,
  formIsValid: true,
  isBusiness: false,
  business: {
    name: '',
    vatNumber: '',
  },
  user: {
    email: '',
    emailConfirm: '',
    phone: '',
    marketingConsent: false,
  },

  /**
   * Is true if user clicks become member on single wash link
   */
  isSingle: false,

  /**
   * Used primarily for animation handling; contains the UUID of the membership,
   * currently being removed from the list
   */
  isRemovingMembership: false,
  /**
   * A list of all the membershipIds that was added (saved in localStorage)
   */
  membershipIds: [],
  /**
   * Is set to true if plan was set from query,
   * is set back to false when user changes plan on **the first plan only**
   * otherwise, it's still true
   */
  planSelectedFromQuery: false,
  /**
   * lpn prefill from query
   */
  lpnAddedFromQuery: false,
  /**
   * Used when user is clicking "Already a member? Login here"
   */
  loginAsExistingUser: false,
  /**
   * Stores the payment session id used for the payment window
   */
  paymentSession: null,
  /**
   * Contains the order id of the last completed order
   */
  orderId: null,
  /**
   * Saved from query /checkout/info?c=<someCode>
   */
  couponCode: null,
  /**
   * Saved from query /checkout/info?s=<someCode>
   */
  terminalSession: null,
  /**
   * Request user to login before continuing
   */
  forceLogin: false,
  // Contains membership agreement id
  membershipAgreement: null,
  /**
   * Contains the selected plan
   */
  selectedPlan: null,
  /**
   * Contains the current checkout step
   */
  checkoutStep: 'membership',
  /**
   * Contains information regarding the membership
   */
  membershipDetails: {
    location: null,
    licensePlate: null,
    hasSelectedAtav: false,
  },

  test: undefined,
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setMembershipIds(state, { payload }) {
      state.membershipIds = payload || [];
    },

    orderCompleted(state, { payload }) {
      const { terminalSession, checkoutStep, couponCode } = state;
      Object.assign(state, initialState);
      state.orderId = payload || null;
      state.terminalSession = terminalSession || null;
      state.checkoutStep = checkoutStep;
      state.couponCode = couponCode || null;
    },

    setCouponCode(state, { payload }) {
      state.couponCode = payload || null;
    },

    setOrderId(state, { payload }) {
      state.orderId = payload || null;
    },

    removeMembership(state, { payload: idToRemove }) {
      state.membershipIds = state.membershipIds.filter(
        (uid) => uid !== idToRemove
      );
    },

    setFormIsValid(state, { payload }) {
      state.formIsValid = payload;
    },

    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },

    setIsValidating(state, { payload }) {
      state.formIsValidating = payload;
    },

    setChosenPlan(state, { payload }) {
      state.selectedPlan = payload;
    },

    setUserData(
      state,
      { payload: { businessName, businessVatNumber, ...payload } }
    ) {
      state.user = payload;
      state.business = {
        name: businessName,
        vatNumber: businessVatNumber,
      };
    },

    setPaymentSession(state, { payload }) {
      state.paymentSession = payload || null;
    },

    setIsRemovingMembership(state, { payload }) {
      state.isRemovingMembership = !!payload;
    },

    setPlanSelectedFromQuery(state, { payload }) {
      state.planSelectedFromQuery = payload;
    },

    setLpnAddedFromQuery(state, { payload }) {
      state.lpnAddedFromQuery = payload;
    },

    setLoginAsExistingUser(state, { payload }) {
      state.loginAsExistingUser = payload;
    },

    setIsBusiness(state, { payload }) {
      state.isBusiness = payload;
    },

    reset(state, { payload = {} }) {
      Object.assign(state, { ...initialState, ...payload });
    },

    setIsSingle(state, { payload }) {
      state.isSingle = payload;
    },

    setTerminalSession(state, { payload }) {
      state.terminalSession = payload || null;
    },
    setForceLogin(state, { payload }) {
      state.forceLogin = payload;
    },

    setAgreement(state, { payload }) {
      state.membershipAgreement = payload;
    },

    setCheckoutStep(state, { payload }) {
      state.checkoutStep = payload;
    },

    setErrorComponent(state, { payload }) {
      state.errorComponent = payload;
    },

    setMembershipDetails(state, { payload }) {
      state.membershipDetails = payload;
    },
  },
  extraReducers: (builder) => {
    // loading state
    builder.addMatcher(
      isAnyOf(
        checkoutApi.endpoints.createMembership.matchPending,
        checkoutApi.endpoints.updateMembership.matchPending,
        userApi.endpoints.createUser.matchPending,
        userApi.endpoints.updateUser.matchPending,
        userApi.endpoints.updateSubscriptions.matchPending,
        commonApi.endpoints.validateLicensePlate.matchPending
      ),
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        checkoutApi.endpoints.createMembership.matchRejected,
        checkoutApi.endpoints.createMembership.matchFulfilled,
        checkoutApi.endpoints.updateMembership.matchRejected,
        checkoutApi.endpoints.updateMembership.matchFulfilled,
        checkoutApi.endpoints.getSummaries.matchRejected,
        checkoutApi.endpoints.getSummaries.matchFulfilled,

        // checkoutApi.endpoints.getSummaries.matchRejected, // this will be trigger, even if request does not fail, simply because its being blocked by a similar request
        userApi.endpoints.createUser.matchFulfilled,
        userApi.endpoints.createUser.matchRejected,
        userApi.endpoints.updateUser.matchFulfilled,
        userApi.endpoints.updateUser.matchRejected,
        userApi.endpoints.updateSubscriptions.matchRejected,
        userApi.endpoints.updateSubscriptions.matchFulfilled,
        commonApi.endpoints.validateLicensePlate.matchFulfilled,
        commonApi.endpoints.validateLicensePlate.matchRejected
      ),
      (state) => {
        state.isLoading = false;
      }
    );

    // add membership ids when added
    builder.addMatcher(
      checkoutApi.endpoints.createMembership.matchFulfilled,
      (state, { payload }) => {
        state.membershipIds = [...state.membershipIds, payload.uid];
      }
    );

    // create payment session
    builder.addMatcher(
      checkoutApi.endpoints.createPaymentSession.matchFulfilled,
      (state, { payload: { session } }) => {
        state.paymentSession = session;
      }
    );

    // reset store if no membership when at payment
    builder.addMatcher(
      checkoutApi.endpoints.createPaymentSession.matchRejected,
      (state, { payload }) => {
        if (payload.status === 409) {
          Object.assign(state, { ...initialState });
        }
      }
    );

    // delete expired / invalid memberships
    builder.addMatcher(
      checkoutApi.endpoints.updateMembership.matchRejected,
      (state, { payload, meta }) => {
        if (payload.data.message === 'No membership found') {
          state.membershipIds = state.membershipIds.filter(
            (uid) => uid !== meta.arg.originalArgs.uid
          );
        }
        // Makes sure user can't update already active membership
        if (payload.status === 405) {
          state.membershipIds = [];
        }
      }
    );
  },
});

export const {
  setMembershipIds,
  addMembership,
  removeMembership,
  setFormIsValid,
  setChosenPlan,
  setIsLoading,
  setUserData,
  orderCompleted,
  setPaymentSession,
  setCouponCode,
  setIsValidating,
  setPlanSelectedFromQuery,
  setLpnAddedFromQuery,
  setIsRemovingMembership,
  setLoginAsExistingUser,
  setOrderId,
  setIsBusiness,
  reset,
  setIsSingle,
  setTerminalSession,
  setForceLogin,
  setAgreement,
  setErrorComponent,
  setCheckoutStep,
  setMembershipDetails,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
