import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import Arrow from '@icons/arrow.svg';
import ArrowLeft from '@icons/arrow-left.svg';
import LoadingIcon from '@components/LoadingIcon';
import {
  Root,
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge,
  IsPrimary,
  IsSecondary,
  IsWhite,
  IsSplash,
  IsInProgress,
  IsDark,
  IsIcon,
  IsText,
  IsLoading,
  Icon as IconClass,
  IconLeft as IconLeftClass,
  BorderBottomBorderClass,
  IsDisabled,
  FullWidth,
  ButtonText,
  HasMobileIcon,
  MobileIconWrapper,
  IsMobileButton,
  NoIconAnimation,
  LoadingWrapper,
  IsBold,
} from './Button.module.css';
import { ButtonStyle } from '@shared/types/button';

const SIZES = {
  'extra-small': ExtraSmall,
  small: Small,
  medium: Medium,
  large: Large,
  'extra-large': ExtraLarge,
};
const APPEARANCES = {
  primary: IsPrimary,
  secondary: IsSecondary,
  'in-progress': IsInProgress,
  white: IsWhite,
  dark: IsDark,
  splash: IsSplash,
  icon: IsIcon,
  text: IsText,
};

interface ButtonProps {
  className?: string;
  children: ReactNode;
  element?: string | FC<any>;
  appearance?: ButtonStyle.Appearance | string;
  size?: ButtonStyle.Size | string;
  hasArrow?: boolean;
  hasArrowLeft?: boolean;
  disabled?: boolean;
  hasBottomBorder?: boolean;
  fullWidth?: boolean;
  icon?: string | FC<any>;
  iconLeft?: string | FC<any>;
  mobileIcon?: string | FC<any> | null;
  innerWrapperClass?: string;
  type?: string;
  isMobileButton?: boolean;
  noIconAnimation?: boolean;
  isLoading?: boolean;
  isBold?: boolean;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  appearance = 'secondary',
  children,
  className = '',
  element: Element = 'button',
  size = 'medium',
  hasArrow = true,
  hasArrowLeft = false,
  hasBottomBorder = false,
  disabled = false,
  fullWidth = false,
  icon: Icon = Arrow,
  iconLeft: IconLeft = ArrowLeft,
  mobileIcon: MobileIcon = null,
  innerWrapperClass = '',
  isMobileButton = false,
  noIconAnimation = false,
  isLoading,
  isBold = false,
  onClick,
  ...props
}: ButtonProps) => {
  const classes = classnames(
    Root,
    {
      [IsLoading]: isLoading,
      [SIZES[size]]: SIZES[size],
      [APPEARANCES[appearance]]: APPEARANCES[appearance],
      [IsDisabled]: disabled,
      [FullWidth]: fullWidth,
      [HasMobileIcon]: MobileIcon,
      [IsMobileButton]: isMobileButton,
      [NoIconAnimation]: noIconAnimation,
      [IsBold]: isBold,
    },
    className
  );

  const innerWrapperClasses = classnames(ButtonText, innerWrapperClass);

  const isDisabled = disabled || isLoading;

  return (
    <Element
      className={classes}
      disabled={isDisabled}
      {...props}
      onClick={onClick}
    >
      {hasArrowLeft && !['icon', 'text'].includes(appearance) && !isLoading ? (
        <IconLeft className={IconLeftClass} />
      ) : null}
      <span className={innerWrapperClasses}>{children}</span>
      {hasArrow && !['icon', 'text'].includes(appearance) && !isLoading ? (
        <Icon className={IconClass} />
      ) : null}
      {isLoading && (
        <div className={LoadingWrapper}>
          <LoadingIcon
            noMaxWait
            color={appearance === 'primary' ? 'white' : null}
          />
        </div>
      )}
      {hasBottomBorder ? (
        <div className={BorderBottomBorderClass}>
          <span />
        </div>
      ) : null}
      {MobileIcon ? (
        <div className={MobileIconWrapper}>
          <MobileIcon />
        </div>
      ) : null}
    </Element>
  );
};

export default Button;
