import { createSlice } from '@reduxjs/toolkit';
import { SnackBarData } from '@shared/types/common';

interface CommonSliceProps {
  disableModalOutsideClick: boolean;
  snackbarData: SnackBarData | undefined;
  isLoading: boolean;
}

const initialState: CommonSliceProps = {
  disableModalOutsideClick: false,
  snackbarData: undefined,
  isLoading: false,
};

const commonSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDisableModalOutsideClick(state, { payload }) {
      state.disableModalOutsideClick = payload || false;
    },
    setSnackbarData(state, { payload }) {
      state.snackbarData = payload || undefined;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload || false;
    },
  },
});

export const { setDisableModalOutsideClick, setSnackbarData, setIsLoading } =
  commonSlice.actions;

export default commonSlice.reducer;
