import Image from 'next/image';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ImageStyle } from './WPImage.module.css';
import { useEffect, useState } from 'react';

const WPImage = ({
  sourceUrl,
  altText,
  className,
  height,
  width,
  mediaDetails,
  isHeroImage,
}) => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const classes = classnames(ImageStyle, className);

  const WPParentContainer = 'wp-image-container';

  useEffect(() => {
    const handleResize = () => {
      const parent = document.getElementById(WPParentContainer);
      if (parent) {
        const { width, height } = parent.getBoundingClientRect();
        setContainerSize({ width, height });
      }
    };

    handleResize(); // Initial call to set dimensions
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageHeight = mediaDetails?.height || height || 50;
  const imageWidth = mediaDetails?.width || width || 50;

  const imageWidthHero = Math.min(containerSize.width, imageWidth);

  const imageHeightHero = Math.min(containerSize.height, imageHeight);

  if (isHeroImage) {
    return (
      <div id={WPParentContainer}>
        <Image
          width={imageWidthHero}
          height={imageHeightHero}
          className={classes}
          src={sourceUrl || '/assets/images/1-grey-pixel.jpg'}
          alt={altText}
          blurDataURL="blur"
          placeholder="blur"
          quality={65}
          priority
        />
      </div>
    );
  }

  return (
    <Image
      width={imageWidth}
      height={imageHeight}
      className={classes}
      src={sourceUrl || '/assets/images/1-grey-pixel.jpg'}
      alt={altText}
      blurDataURL="blur"
      placeholder="blur"
      quality={65}
      priority
    />
  );
};

WPImage.defaultProps = {
  layout: 'responsive',
  altText: 'Image',
};

WPImage.propTypes = {
  sourceUrl: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(['fixed', 'intrinsic', 'responsive', 'fill']),
  altText: PropTypes.string,
};

export default WPImage;
